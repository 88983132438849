import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import { useEmblaCarousel } from "embla-carousel/react";
import { Reveal } from "src/components/global/reveal";

import {
	IntroCopySlide,
	MultiLayoutSlide,
	PaintSlide,
} from "src/components/carousel-slides";

import { ReactComponent as ArrowRight } from "src/images/svg/arrow-right-variable.svg";

// Import component styles
import styles from "./apartments-carousel.module.css";

interface IntroSlideProps {
	verticalTitleSvg: {
		asset: {
			url: string;
		};
	};
}

const IntroSlide = ({ verticalTitleSvg }: IntroSlideProps) => {
	return (
		<div key="intro" style={{ width: "auto" }} className={cx(styles.slide)}>
			<div className={styles.slideInner}>
				{ verticalTitleSvg.asset.url && (
					<div className={styles.verticalTitleSvgWrapper}>
						<img
							className={styles.verticalTitleSvg}
							src={verticalTitleSvg.asset.url}
						/>
						<ArrowRight className={styles.verticalTitleArrow} />
					</div>
				)}
			</div>
		</div>
	);
};

interface Slide {
	_key: string;
	_type: string;
	description: string;
	title: string;
	headline?: string;
	media?: any;
	caption?: string;
	vendorLogo?: {
		asset: {
			url: string;
		};
	};
	paintColors?: any;
}

export interface ApartmentsCarouselProps {
	title?: string;
	slides: Slide[];
	verticalTitleSvg: {
		asset: {
			url: string;
		};
	};
	unitCategories: UnitCategory[];
	buildingSlug: string;
}

export const ApartmentsCarousel = ({
	title,
	slides,
	verticalTitleSvg,
	unitCategories,
	buildingSlug,
}: ApartmentsCarouselProps) => {
	const [viewportRef, embla] = useEmblaCarousel({
		align: "start",
		containScroll: "trimSnaps",
		dragFree: true,
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		embla.on("select", onSelect);
		onSelect();
	}, [embla, onSelect]);

	return (
		<Reveal style="borderBottom">
			<div className={cx(`${styles.carouselContainer} ${verticalTitleSvg.asset.url && styles.carouselContainerWithVerticalSvg}`)}>
				<div className="embla">
					<div className="embla__viewport" ref={viewportRef}>
						<div className={cx("embla__container", styles.carouselSlides)}>
							<IntroSlide
								buildingSlug={buildingSlug}
								unitCategories={unitCategories}
								verticalTitleSvg={verticalTitleSvg}
							/>
							{slides.map((slide) => {
								const layout = slide._type;
								switch (layout) {
									case "carouselSlideCopy":
										return (
											<IntroCopySlide
												key={slide._key}
												slide={slide}
												goNext={scrollNext}
												goPrev={scrollPrev}
											/>
										);
									case "carouselSlidePaint":
										return (
											<PaintSlide
												key={slide._key}
												slide={slide}
												goNext={scrollNext}
												goPrev={scrollPrev}
											/>
										);
									default:
										return (
											<MultiLayoutSlide
												key={slide._key}
												slide={slide}
												goNext={scrollNext}
												goPrev={scrollPrev}
											/>
										);
								}
							})}
						</div>
					</div>
					{prevBtnEnabled && (
						<div
							className="embla__full-height-button-container"
							data-custom-cursor="arrowLeft"
						>
							<button
								className="embla__button embla__button--prev"
								onClick={scrollPrev}
								disabled={!prevBtnEnabled}
							/>
						</div>
					)}
				</div>
			</div>
		</Reveal>
	);
};
