import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import { useEmblaCarousel } from "embla-carousel/react";
import { Reveal } from "src/components/global/reveal";
import {
	IntroCopySlide,
	MultiLayoutSlide,
	PaintSlide,
} from "src/components/carousel-slides";

// Import component styles
import styles from "./amenity-carousel.module.css";

interface Slide {
	_key: string;
	_type: string;
	description: string;
	title: string;
	layout?: string;
	media?: any;
	headline?: string;
	caption?: string | [];
}

export interface AmenityCarouselProps {
	index: number;
	graphic?: {
		asset: {
			url: string;
		};
	};
	slides: Slide[];
}

export const AmenityCarousel = ({
	index,
	graphic,
	slides,
}: AmenityCarouselProps) => {
	const [viewportRef, embla] = useEmblaCarousel({
		align: "start",
		containScroll: "keepSnaps",
		dragFree: true,
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		embla.on("select", onSelect);
		onSelect();
	}, [embla, onSelect]);

	return (
		<Reveal style="borderBottom">
			<div className={styles.carouselOuter}>
				{graphic && (
					<div className={styles.stickyGraphic}>
						<img src={graphic.asset.url} />
					</div>
				)}
				<div
					className={cx(
						styles.carouselContainer,
						index === 0 ? styles.first : ""
					)}
				>
					<div className="embla">
						<div className="embla__viewport" ref={viewportRef}>
							<div className={cx("embla__container", styles.carouselSlides)}>
								{slides.map((slide) => {
									switch (slide._type) {
										case "carouselSlideCopy":
											return (
												<IntroCopySlide
													key={slide._key}
													slide={slide}
													headlineStyle={index & 1 ? "serif" : "sans"}
													goNext={scrollNext}
													goPrev={scrollPrev}
												/>
											);
										case "carouselSlidePaint":
											return (
												<PaintSlide
													key={slide._key}
													slide={slide}
													goNext={scrollNext}
													goPrev={scrollPrev}
												/>
											);
										default:
											return (
												<MultiLayoutSlide
													key={slide._key}
													slide={slide}
													goNext={scrollNext}
													goPrev={scrollPrev}
												/>
											);
									}
								})}
								<div className={styles.spacerSlide} />
							</div>
						</div>
						{prevBtnEnabled && (
							<div
								className="embla__full-height-button-container"
								data-custom-cursor="arrowLeft"
							>
								<button
									className="embla__button embla__button--prev"
									onClick={scrollPrev}
									disabled={!prevBtnEnabled}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</Reveal>
	);
};
