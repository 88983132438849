import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";

// Import component styles
import styles from "./additional-amenities.module.css";
import { AmenityCarousel } from "../amenity-carousel";

interface Amenity {
	_key: string;
	title: string;
	subheadline?: string;
}

export interface AdditionalAmenitiesProps {
	amenities: Amenity[];
}

export const AdditionalAmenities = ({
	amenities,
}: AdditionalAmenitiesProps) => {
	const titleClasses = [
		"serif serif--100 lower",
		"sans sans--90 lower",
		"sans sans--90 lower",
		"serif serif--100 lower",
	];
	return (
		<div className={styles.additionalAmenities}>
			<div className={styles.inner}>
				<ul className={styles.amenityList}>
					{amenities.map((amenity: Amenity, index: number) => (
						<li className={styles.amenityListItem} key={amenity._key}>
							<div className={styles.amenityListItemInner}>
								<h2 className={cx(titleClasses[index % 4], styles.title)}>
									{amenity.title}
								</h2>
								{amenity.subheadline && (
									<span
										className={cx("sans sans--caps-11", styles.subheadline)}
									>
										{amenity.subheadline}
									</span>
								)}
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
