import React from "react";
import cx from "classnames";
import { BlockCopy } from "src/components/global/block-copy";
import { Image } from "src/components/image";

// Import component styles
import styles from "./carousel-slides.module.css";

interface MultiLayoutSlideProps {
	_key: string;
	_type: string;
	hide?: boolean;
	layout?: string;
	headline?: string;
	caption?: string | [];
	media?: any;
	mobileMedia?: any;
	svgPoster?: any;
}

export const MultiLayoutSlide = ({
	slide,
	goNext,
	goPrev,
}: {
	slide: MultiLayoutSlideProps;
	goNext: () => void;
	goPrev?: () => void;
}) => {
	const getCopyClasses = (layout: string) => {
		switch (layout) {
			case "layout1":
				return {
					headlineClasses: ["sans", "sans--caps-50"],
					captionClasses: ["serif", "serif--18"],
				};
			case "layout2":
				return {
					headlineClasses: [""],
					captionClasses: ["serif", "serif--18"],
				};
			case "layout3":
				return {
					headlineClasses: ["serif", "serif--60", "lower"],
					captionClasses: ["sans", "sans--12"],
				};
			default:
				return {
					headlineClasses: ["sans", "sans--caps-50"],
					captionClasses: ["serif", "serif--18"],
				};
		}
	};
	const { layout, headline, caption, media, mobileMedia, svgPoster } = slide;

	const {
		headlineClasses,
		captionClasses,
	}: {
		headlineClasses: string[] | null;
		captionClasses: string[] | null;
	} = getCopyClasses(layout);

	if (slide.hide) {
		return null;
	}

	return (
		<div
			tabIndex={1}
			className={cx(styles.slide)}
			onClick={() => goNext()}
			data-custom-cursor="arrowRight"
		>
			<div className={styles.slideInner}>
				{svgPoster && (
					<div
						className={cx(styles.svgPosterContainer)}
						style={
							{
								"--aspect-ratio":
									svgPoster.asset?.metadata?.dimensions?.aspectRatio,
							} as React.CSSProperties
						}
					>
						<img className={styles.svgPoster} src={svgPoster.asset.url} />
					</div>
				)}
				<div className={styles[layout]}>
					<div
						className={cx(
							styles.slideMediaWrapper,
							mobileMedia && mobileMedia.image && mobileMedia.image.asset
								? styles.hasMobileImage
								: "",
							mobileMedia && mobileMedia.mp4 && mobileMedia.mp4.asset
								? styles.hasMobileVideo
								: ""
						)}
						style={
							{
								"--aspect-ratio":
									media.image.asset &&
									media.image.asset.metadata.dimensions.aspectRatio,
							} as React.CSSProperties
						}
					>
						{media && media.mp4 && media.mp4.asset ? (
							<video
								className={styles.slideVideo}
								preload="auto"
								playsInline={true}
								autoPlay={true}
								muted={true}
								loop={true}
							>
								<source src={media.mp4.asset.url} type="video/mp4" />
								<Image
									className={styles.slideImage}
									imageId={media.image.asset._id}
									style={{ width: "100%", height: "100%" }}
								/>
							</video>
						) : media.image && media.image.asset ? (
							<Image
								className={styles.slideImage}
								imageId={media.image.asset._id}
								src={media.image.asset.url}
								style={{ width: "100%", height: "100%" }}
							/>
						) : null}
						{mobileMedia && mobileMedia.mp4 && mobileMedia.mp4.asset ? (
							<video
								className={styles.slideVideoMobile}
								preload="auto"
								playsInline={true}
								autoPlay={true}
								muted={true}
								loop={true}
							>
								<source src={mobileMedia.mp4.asset.url} type="video/mp4" />
								<Image
									className={styles.slideImageMobile}
									imageId={mobileMedia.image.asset._id}
									style={{ width: "100%", height: "100%" }}
								/>
							</video>
						) : mobileMedia && mobileMedia.image && mobileMedia.image.asset ? (
							<Image
								className={styles.slideImageMobile}
								imageId={mobileMedia.image.asset._id}
								style={{ width: "100%", height: "100%" }}
							/>
						) : null}
					</div>
					<div className={styles.copy}>
						{headline && (
							<h3 className={cx(...headlineClasses, styles.headline)}>
								{headline}
							</h3>
						)}
						{caption && (
							<BlockCopy
								className={cx(...captionClasses, styles.caption)}
								content={caption}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
