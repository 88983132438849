import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";

import { Image } from "src/components/image";

// Import component styles
import styles from "./carousel-slides.module.css";

export interface PaintSlideProps {
	_key: string;
	_type: string;
	hide?: boolean;
	headline?: string;
	caption?: string | [];
	paintColors?: [
		{
			_key: string;
			swatchImage: {
				asset: {
					_id: string;
				};
			};
			mainImage: {
				asset: {
					_id: string;
					metadata: {
						dimensions: {
							aspectRatio: number;
						};
					};
				};
			};
		}
	];
	vendorLogo?: {
		asset: {
			url: string;
		};
	};
	svgPoster?: any;
}

export const PaintSlide = ({
	slide,
	goNext,
	goPrev,
}: {
	slide: PaintSlideProps;
	goNext: () => void;
	goPrev?: () => void;
}) => {
	const [activeColor, setActiveColor] = useState(0);

	const handleSwatchClick = (index: number) => {
		setActiveColor(index);
	};

	const { headline, caption, paintColors, vendorLogo, svgPoster } = slide;

	if (slide.hide) {
		return null;
	}

	return (
		<div tabIndex={1} className={cx(styles.slide, styles.paintSlide)}>
			<div className={styles.slideInner}>
				{svgPoster && (
					<div
						className={cx(styles.svgPosterContainer)}
						onClick={() => goNext()}
						data-custom-cursor="arrowRight"
						style={
							{
								"--aspect-ratio":
									svgPoster.asset?.metadata?.dimensions?.aspectRatio,
							} as React.CSSProperties
						}
					>
						<img className={styles.svgPoster} src={svgPoster.asset.url} />
					</div>
				)}
				<div>
					<div
						className={styles.mainImageWrapper}
						style={
							{
								"--aspect-ratio":
									paintColors &&
									paintColors[0].mainImage.asset &&
									paintColors[0].mainImage.asset.metadata.dimensions
										.aspectRatio,
							} as React.CSSProperties
						}
						onClick={() => goNext()}
						data-custom-cursor="arrowRight"
					>
						{paintColors &&
							paintColors.map((color, index) => (
								<Image
									key={color._key}
									className={cx(
										styles.mainImage,
										index === activeColor ? styles.active : ""
									)}
									imageId={color.mainImage.asset._id}
									style={{ height: "100%" }}
									objectFit="cover"
								/>
							))}
					</div>
					<div
						className={styles.copy}
						onClick={() => goNext()}
						data-custom-cursor="arrowRight"
					>
						{headline && (
							<h3
								className={cx("serif", "serif--60", "lower", styles.headline)}
							>
								{headline}
							</h3>
						)}
						{caption && (
							<p className={cx("sans", "sans--12", styles.caption)}>
								{caption}
							</p>
						)}
						{vendorLogo && (
							<img className={styles.vendorLogo} src={vendorLogo.asset.url} />
						)}
					</div>
				</div>
				<div>
					<div className={styles.swatches}>
						{paintColors &&
							paintColors.map((color, index) => (
								<button
									onClick={() => handleSwatchClick(index)}
									className={cx(
										styles.swatchButton,
										index === activeColor ? styles.active : ""
									)}
									key={color._key}
								>
									<Image
										key={color._key}
										className={styles.swatchImage}
										imageId={color.swatchImage.asset._id}
									/>
								</button>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};
