import React from "react";
import cx from "classnames";

import { ReactComponent as ArrowRight } from "src/images/svg/arrow-right.svg";

// Import component styles
import styles from "./carousel-slides.module.css";

interface IntroCopySlideProps {
	_key: string;
	_type: string;
	headline?: string;
	copy?: string;
}

export const IntroCopySlide = ({
	slide,
	headlineStyle,
	goNext,
	goPrev,
}: {
	slide: IntroCopySlideProps;
	headlineStyle?: string;
	goNext?: () => void;
	goPrev?: () => void;
}) => {
	const { _type: layout, headline, copy } = slide;
	return (
		<div
			className={cx(styles.slide)}
			tabIndex={1}
			onClick={() => goNext()}
			data-custom-cursor="arrowRight"
		>
			<div className={styles.slideInner}>
				<div className={styles.carouselSlideCopy}>
					<div className={styles.carouselSlideCopyInner}>
						<h2
							className={cx(
								headlineStyle === "sans"
									? "sans sans--90 lower"
									: "serif serif--100 lower",
								styles.headline
							)}
						>
							{headline}
						</h2>
						<h2 className={styles.headlineMobile}>
							<span
								className={cx("sans sans--90 lower", styles.headlineMobileCopy)}
							>
								{headline}
							</span>
						</h2>
						<div className={styles.copyContainer}>
							{copy && (
								<p className={cx("serif serif--26 lower", styles.copy)}>
									{copy}
								</p>
							)}
							<ArrowRight className={styles.arrowRight} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
