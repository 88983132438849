import React from "react";
import { Reveal } from "src/components/global/reveal";

// Import component styles
import styles from "./outlined-label-graphic.module.css";

export const OutlinedLabelGraphic = ({ SVGComponent, modifierClass = "", decoration = "" }) => {
	return (
		<Reveal style="borderBottom">
			<div className={styles.outlinedLabelGraphic}>
				<SVGComponent className={styles[modifierClass]} />
				{ decoration && (
					<span className={styles.outlinedLabelGraphicDecoration}>{decoration}</span>
				)}
			</div>
		</Reveal>
	);
};
